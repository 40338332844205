import React from "react";
import Layout from "../../components/layout";
import { Link } from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Img from "gatsby-image";
import { graphql } from 'gatsby';
const industrialPaintsPage = ({data}) => (
    <Layout>
        <Row>
            <Col><h1>INDUSTRIAL PAINTS</h1>
                <p>We are a prominent manufacturer and Supplier of Synthetic Enamel, Stoving enamel, Quick Dry, N.C Paint, Epoxy paints, P.U PAINTS, Primer and Thinner etc. The products are accessible in different colors, shades and specifications as per the requirement of the customers. They are resistant to high temperature as well as tough weather conditions. Owing to our industrial expertise and manufacturing facilities, we have been able to cater an assorted range of industrial paints and thinners.</p></Col>
        </Row>

        <Row><Col>
            <h2>Enamels</h2></Col>
        </Row>

        <Row>
            <Col xs={12} sm={8}><p>Enamel paint is paint that air dries to a hard, usually glossy, finish, used for coating surfaces that are outdoors or otherwise subject to hard wear or variations in temperature. It should not be confused with decorated objects in “painted enamel” where vitreous enamel is applied with brushes and fired in a kiln.</p>
                <Link to="/products/enamels/">View Products</Link>
            </Col>

            <Col xs={12} sm={4} md={{span:3,offset:1}}><Img fluid={data.syntheticEnamelsMain.childImageSharp.fluid}></Img></Col>
        </Row>
        <hr />
        {/* primer */}

        <Row>
            <Col>
                <h2>Primer</h2></Col>
        </Row>
        <Row>
            <Col xs={12} sm={8}>
                <p>Primer is a paint product that allows finishing paint to adhere much better than if it were used alone. For this purpose, primer is designed to adhere to surfaces and to form a binding layer that is better prepared to receive the paint. Because primers do not need to be engineered to have durable, finished surfaces, they can instead be engineered to have improved filling and binding properties with the material underneath. Sometimes, this is achieved with specific chemistry, as in the case of aluminium primer, but more often, this is achieved through controlling the primer’s physical properties such as porosity, tackiness, and hygroscopy.
            </p>
                <Link to="/products/primer/">View Products</Link>
            </Col>
            <Col xs={12} sm={4} md={{span:3,offset:1}}><Img fluid={data.primerMain.childImageSharp.fluid}></Img></Col>
        </Row>
        <hr />
        {/* Thinner */}
        <Row>
            <Col>
                <h2>Thinner</h2></Col>
        </Row>
        <Row>
            <Col xs={12} sm={8}>
                <p>A paint thinner is a solvent used to thin oil-based paints or clean up after their use. Commercially, solvents labeled “Paint Thinner” are usually mineral spirits having a flash point at about 40 °C (104 °F), the same as some popular brands of charcoal starter.
            </p>
                <Link to="/products/thinner/">View Products</Link>
            </Col>
            <Col xs={12} sm={4} md={{span:3,offset:1}}><Img fluid={data.thinnerMain.childImageSharp.fluid}></Img></Col>
        </Row>

    </Layout>
)

export default industrialPaintsPage; 
export const query = graphql`
query {
    thinnerMain: file(relativePath: { eq: "thinnerMain.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    syntheticEnamelsMain: file(relativePath: { eq: "syntheticEnamelsMain.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      primerMain: file(relativePath: { eq: "primerMain.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
}`